import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions/index';
//import classes from './Logout.css';

class Logout extends Component {
	componentDidMount () {
		this.props.onLogout(this.props.history);
	}

	render() {
		return <Redirect to="/" />;
	}
}

const mapDispatchToProps = dispatch => {
	return{
		onLogout:() => dispatch(actions.logout())
	}
}

export default connect(null, mapDispatchToProps)(Logout);