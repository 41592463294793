import React from 'react';

import DescriptionContainer from './DescriptionContainer/DescriptionContainer';

import bathroomRemodel from '../../assets/images/bathroom_remodel.png';
import kitchenRemodel from '../../assets/images/kitchen_remodel.png';
import customRemodel from '../../assets/images/custom_homes.png';


const descriptionContainers = (props) => {
    const bathroomText = "One of our specialties is bathroom remodeling. We can upgrade bathroom fixtures or do a complete remodel job."
    const kitchenText = "The Vazquez Firm Construction Group is your one stop general contractor for any construction job including Kitchen Remodeling."
    const customText = "Want to build a custom home from scratch? We do that too! Let us help you plan, design and build your dream home!"
    return(
        <>
            <DescriptionContainer containerImage={bathroomRemodel} containerText={bathroomText} position={0} />
            <DescriptionContainer containerImage={kitchenRemodel} containerText={kitchenText} position={1} />
            <DescriptionContainer containerImage={customRemodel} containerText={customText} position={2} />
        </>
        
    );
};

export default descriptionContainers;