import React, { Component } from 'react';

import profile1Image from '../../assets/images/Profiles/Profile1.jpg'
import classes from './AboutUsPage.module.css';

export class AboutUsPage extends Component{
    render () {
        return(
            <div className={classes.AboutUsPage}>
                <h1>About Us</h1>
                <div className={classes.DivRow}>
                    <div className={classes.HalfWidth}>
                        <img src={profile1Image} alt="profileImage1"></img>
                        <p className={classes.ProfileDescription}>Miguel A. Vazquez / President</p>
                    </div>
                    <div className={classes.HalfWidth}>
                        <p className={classes.SharedImageP}>Our Mission:
To provide excellent service and quality work in the
most professional way. Our team of professionals has
grown over the years; each member partakes in our
mission of delivering work on time and within budget.
Every effort is made to reach the client’s expectations,
maintaining quality and excellence at all times. No job
is too big or too small; we ensure professional service
regardless of the size of the project. Professionalism,
Excellence and Quality are intrinsic elements of our
Mission.</p>
<p className={classes.SharedImageP}>We are a construction, consultation and referral company with more than thirty years of experience
constructing in the greater Los Angeles Area. We have a network of professionals; each person has been
selected over the years. We take great consideration and serious thought to provide the right
professional for your project. When you call, you can be sure that the work will be done to your
expectations in the most respectful and professional manner. We maintain a great reputation with
our clients because we are always there to ensure that they get exactly what they envisioned.</p>
                    </div>
                    
                </div>
                


            </div>
        )
    }
}

export default AboutUsPage;