import React, { Component } from 'react';
import DescriptionContainers from '../../components/DescriptionContainers/DescriptionContainers';

import bannerImage from '../../assets/images/house-banner-image.png'
import classes from './HomePage.module.css';

export class HomePage extends Component{
    render () {
        return(
            <div className={classes.HomePage}>
                <img src={bannerImage} alt="Banner" />
                <DescriptionContainers/>
            </div>
        )
    }
}

export default HomePage;