import React from 'react';

import classes from './Footer.module.css';

const footer = ( props ) => (
    <div className={classes.Footer}>
        <h1>Contact Us</h1>
        <p>Cell: (310) 844 2633</p>
        <p>Email: tvfi@aol.com</p>
        <p>Email: miguelv@thevazquezfirm.com</p>
        <a href="https://firebasestorage.googleapis.com/v0/b/vazquez-firm.appspot.com/o/pdfs%2FTVFI%20Brochure%20English.pdf?alt=media&token=fc5cb3ec-0c38-4c70-870f-428a1df02b89" target="_blank" rel="noopener noreferrer">View Our Pamphlet</a>
        <br />
        <a href="https://firebasestorage.googleapis.com/v0/b/vazquez-firm.appspot.com/o/pdfs%2FTVFI%20Brochure%20Spanish.pdf?alt=media&token=a209ef48-fe4d-4821-86a8-2b424305da93" target="_blank" rel="noopener noreferrer">Ver Nuestro Folleto</a>
    </div>
);

export default footer;


// +  Success! Use this token to login on a CI server:

//  1//06N7WfuPqK8SCCgYIARAAGAYSNwF-L9IrTP-NB--tu6LoAuPsxaZxWyUo8JkvCu_HSkwHQPO3-1fRXEgjPnGWGeQwP1Pzi3oj2fA

// Example: firebase deploy --token "$FIREBASE_TOKEN"