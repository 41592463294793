import React from 'react';

import classes from'./DescriptionContainer.module.css'

const descriptionContainer = (props) => {
    let descriptionContent = null;
    let attachedClasses = [classes.descriptionContainer];
    if(props.position % 2 === 0){
        attachedClasses.push(classes.yellowBackground);

        descriptionContent = (
            <div className={attachedClasses.join(' ')}>
                <div className={classes.imageContainer}>
                    <img src={props.containerImage} alt="DescriptionImage"/>
                </div>
                <span className={classes.spacer}></span>
                <div className={classes.textContainer}>
                    <p>{props.containerText}</p>
                </div>
            </div>
        )
    }
    else{
        attachedClasses.push(classes.blackBackground);

        descriptionContent = (
            <div className={attachedClasses.join(' ')}>
                <div className={classes.textContainer}>
                    <p>{props.containerText}</p>
                </div>
                <span className={classes.spacer}></span>
                <div className={classes.imageContainer}>
                    <img src={props.containerImage} alt="DescriptionImage"/>
                </div>
            </div>
        )
    }

    return(
        <>
            {descriptionContent}
        </>
    )
    
};

export default descriptionContainer;