import React from 'react';

import classes from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';

const navigationItems = ( props ) => (
    <ul className={classes.NavigationItems}>
        <NavigationItem link="/" exact>Home</NavigationItem>
        {/* <NavigationItem link="/detail/bathrooms" exact>Bathrooms</NavigationItem>
        <NavigationItem link="/detail/kitchen" exact>Kitchen</NavigationItem>
        <NavigationItem link="/detail/custom" exact>Custom Home</NavigationItem> */}
        <NavigationItem link="/about-us" exact>About Us</NavigationItem>
        {/* <NavigationItem link="/testimonial" exact>Testimonials</NavigationItem> */}
        {/* <NavigationItem link="/auth" exact>Sign In</NavigationItem> */}
        {/* { props.isAuthenticated ? <NavigationItem link="/orders">Orders</NavigationItem>: null} */}
        
        {/* { !props.isAuthenticated 
        	? <NavigationItem link="/auth">Sign In</NavigationItem>
        	: <NavigationItem link="/logout">Logout</NavigationItem>
        } */}
    </ul>
);

export default navigationItems;